import { useState, useEffect } from "react";
import ReactGA from "react-ga4";

const useTracking = () => {
  const sendCustomEvent = (id, category, data) => {
    ReactGA.event(id, {
      category: category,
      //   label: "your label", // optional
      value: 1, // optional, must be a number
      //   nonInteraction: true, // optional, true/false
      //   transport: "xhr", // optional, beacon/xhr/image
    });
  };

  return {
    sendCustomEvent,
  };
};

export { useTracking };
