import React, { useContext, useState, Suspense } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APIProviderContext } from "../../api";
import { toast } from "react-toastify";
import {
  getInitialReactState,
  removeInitialReactStates,
  remove_prefix,
  setInitialReactState,
} from "../../helpers";
import { PLANS } from "../../../plans";
import { PoperAccount } from "./context";
import { AccountContext } from "../../account/account";
import { PulseLoader } from "react-spinners";
import MainSkeleton from "../../skeletons/main-skeleton";
import StartNewLoadable from "../../../pages/loadables/start-new";
import AnalyticsLoadable from "../../../pages/loadables/analytics";
import LeadsLoadable from "../../../pages/loadables/leads";
import TeamsLoadable from "../../../pages/loadables/teams";
import SettingsLoadable from "../../../pages/loadables/settings";
import WebsitesLoadable from "../../../pages/loadables/website";
import AnalyticsRawLoadable from "../../../pages/loadables/analytics-raw";
import CodeEmbedLoadable from "../../../pages/loadables/code-embed";
import Cookies from "js-cookie";
import * as pixel from "./../../fpixel";
import md5 from "md5";
import { GrigoraVersioningContext } from "../../versioning/context";
import { isEmpty } from "lodash-es";
import { useTracking } from "../../tracking";

const ForceUpdateNames = React.lazy(() =>
  import(
    /* webpackChunkName: "component-names" */
    /* webpackMode: "lazy" */
    "./names"
  ),
);

const ProductLiftScript = () => {
  useEffect(() => {
    if (window.ProductLift) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "https://roadmap.poper.ai/widgets_sdk");
    script.setAttribute("defer", true);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export const PoperAccountProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVerifyingDomain, setIsVerifyingDomain] = useState(false);

  const [userData, setUserData] = useState({});

  const { sendCustomEvent } = useTracking();

  const {
    alias,
    setAlias,
    getUserData,
    domainActions,
    acquisitionActions,
    updateUserNames,
    sendHeartbeat,
  } = useContext(APIProviderContext);

  const [currentDomain, setCurrentDomainInternal] = useState(null);
  const [forceUpdateNames, setForceUpdateNames] = useState(false);
  const [forceUpdateUser, setForceUpdateUser] = useState(false);
  const [isAddingNewPopupModalVisible, setIsAddingNewPopupModalVisible] =
    useState(false);
  const [newPopupConfig, setNewPopupConfig] = useState({});

  const [consumptionPercentage, setConsumptionPercentage] = useState();

  const navigate = useNavigate();

  const { userAttributes } = useContext(AccountContext) ?? {};

  const { compareVersion } = useContext(GrigoraVersioningContext);

  useEffect(() => {
    if (!isEmpty(userData)) {
      // Save to cache
      setInitialAccountReactState("userData", userData, true);
    }
  }, [userData]);

  function doUserData(data) {
    setUserData(data);

    var updateUserDataRemote = false;

    var forceUpdateNames = false;

    if (!data?.user?.first_name) {
      // Get first name from session storage
      var firstName = sessionStorage.getItem("firstName");
      if (firstName) {
        setUserData((prev) => {
          return {
            ...prev,
            user: {
              ...prev.user,
              first_name: firstName,
            },
          };
        });
        updateUserDataRemote = true;
      } else if (userAttributes?.["custom:given_name"]) {
        firstName = userAttributes?.["custom:given_name"];
        setUserData((prev) => {
          return {
            ...prev,
            user: {
              ...prev.user,
              first_name: userAttributes?.["custom:given_name"],
            },
          };
        });
        updateUserDataRemote = true;
      } else {
        forceUpdateNames = true;
      }
    }

    if (!data?.user?.last_name) {
      // Get last name from session storage
      var lastName = sessionStorage.getItem("lastName");
      if (lastName) {
        setUserData((prev) => {
          return {
            ...prev,
            user: {
              ...prev.user,
              last_name: lastName,
            },
          };
        });
        updateUserDataRemote = true;
      } else if (userAttributes?.["custom:family_name"]) {
        lastName = userAttributes?.["custom:family_name"];
        setUserData((prev) => {
          return {
            ...prev,
            user: {
              ...prev.user,
              last_name: userAttributes?.["custom:family_name"],
            },
          };
        });
        updateUserDataRemote = true;
      } else {
        forceUpdateNames = true;
      }
    }

    if (updateUserDataRemote) {
      updateUserNames(firstName, lastName);
      sessionStorage.removeItem("firstName");
      sessionStorage.removeItem("lastName");
    }

    var domains = data?.domains ?? [];

    if (domains.length > 0) {
      var previouslySelectedDomain = localStorage.getItem("currentDomain");
      var previouslySelectedDomainObj = domains.find(
        (item) => item.domain === previouslySelectedDomain,
      );
      if (
        previouslySelectedDomain &&
        domains.map((item) => item.domain).includes(previouslySelectedDomain)
      ) {
        setCurrentDomainInternal(previouslySelectedDomain);
        if (previouslySelectedDomainObj?.external) {
          setAlias({
            owner: previouslySelectedDomainObj?.owner_user_email,
          });
        }
      } else {
        setCurrentDomainInternal(domains?.[0]?.domain);
        if (domains?.[0]?.external) {
          setAlias({
            owner: domains?.[0]?.owner_user_email,
          });
        }

        // Save domain to local storage
        localStorage.setItem("currentDomain", domains?.[0]?.domain);
      }
    }
    if (forceUpdateNames) {
      setForceUpdateNames(true);
    }

    // Check if user has completed onboarding flow
    if (data?.user?.version && compareVersion(data.user.version)) {
      if (!data?.user?.onboarding?.completed) {
        // Check if user has been signed up for invitation to collaborate
        if (data?.user?.onboarding?.is_invited_user) {
          // If this kind of user creates his own domain
          if (domains?.filter((item) => !item?.external)?.length > 0) {
            navigate("/onboarding");
          }
        } else {
          navigate("/onboarding");
        }
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    var cache = getInitialAccountReactState("userData", null, true);

    if (cache) {
      // Check if cache is invalid
      if (cache?.user?.user_id) {
        doUserData(cache);
      }
    }

    getUserData()
      .then((data) => {
        doUserData(data);

        setInitialAccountReactState("userData", data, true);
      })
      .catch((error) => {
        setIsLoading(false);
      });

    // Preload Start New page
    StartNewLoadable.preload();

    // Preload Analytics
    AnalyticsLoadable.preload();
    AnalyticsRawLoadable.preload();

    // Preload Leads
    LeadsLoadable.preload();

    // Preload Teams
    TeamsLoadable.preload();

    // Preload Settings
    SettingsLoadable.preload();

    // Preload websites
    WebsitesLoadable.preload();

    // Preload code embed
    CodeEmbedLoadable.preload();

    // Update acquisition if detected when signup
    if (window.POPER_UPDATE_USER_ACQUISITION) {
      window.POPER_UPDATE_USER_ACQUISITION = false;

      try {
        var acquisitionCookies = Cookies.get("poper_acquisition");
        console.log("Found acq cookies", acquisitionCookies);
        // if (acquisitionCookies) {
        try {
          acquisitionCookies = JSON.parse(acquisitionCookies);
        } catch (e) {
          acquisitionCookies = null;
        }

        async function doAcqu() {
          var hasUpdated = await acquisitionActions("update", {
            referrer: acquisitionCookies?.referrer,
            utm_source: acquisitionCookies?.utm?.source,
            utm_medium: acquisitionCookies?.utm?.medium,
            utm_campaign: acquisitionCookies?.utm?.campaign,
            utm_term: acquisitionCookies?.utm?.term,
            utm_content: acquisitionCookies?.utm?.content,
            referrerPage: acquisitionCookies?.referrerPage,
          });

          if (hasUpdated?.hasUpdated) {
            // Send facebook ad event if acquisition is from facebook ads
            if (
              acquisitionCookies?.utm?.source === "facebook" &&
              acquisitionCookies?.utm?.medium === "ads"
            ) {
              pixel.event("CompleteRegistration");
            }

            if (
              acquisitionCookies?.utm?.source === "linkedin" &&
              acquisitionCookies?.utm?.medium === "ads"
            ) {
              try {
                window.lintrk("track", { conversion_id: 17286460 });
              } catch (error) {
                console.log(error);
              }
            }

            if (
              acquisitionCookies?.utm?.source === "google" &&
              acquisitionCookies?.utm?.medium === "ads"
            ) {
              try {
                sendCustomEvent("google_ads_conversion", "signup");
              } catch (error) {
                console.log(error);
              }
            }

            Cookies.remove("poper_acquisition", { domain: ".poper.ai" });
          }
        }

        doAcqu();

        // }
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    if (userData?.user?.first_name && userData?.user?.last_name) {
      setForceUpdateNames(false);
    }
  }, [userData]);

  useEffect(() => {
    if (userData) {
      let percentage =
        ((userData?.usage?.usage?.pageview ?? 0) * 100) /
          currentSubscriptionPlanDetails?.limits?.pageviews ?? 1;
      percentage = Math?.round(percentage * 100) / 100;
      setConsumptionPercentage(percentage);
    }
  }, [userData]);

  function getKeyPrefix() {
    if (alias) {
      return "alias-" + alias.owner + "-";
    } else {
      return userAttributes?.email + "-";
    }
  }

  function getInitialAccountReactState(
    key,
    defaultValue = "",
    isObject = false,
  ) {
    return getInitialReactState(getKeyPrefix() + key, defaultValue, isObject);
  }

  function setInitialAccountReactState(key, value, isObject = false) {
    setInitialReactState(getKeyPrefix() + key, value, isObject);
  }

  function removeInitialAccountReactStates(keys = []) {
    removeInitialReactStates(keys.map((key) => getKeyPrefix() + key));
  }

  const validateNewDomain = async (domain) => {
    return new Promise((resolve, reject) => {
      if (!domain) {
        toast.error("Domain is required");
        reject();
        return;
      }

      // Lazy load parse-domain
      import(
        /* webpackChunkName: "parse-domain" */
        /* webpackMode: "lazy" */
        "parse-domain"
      )
        .then(({ parseDomain, ParseResultType }) => {
          // Remove protocol and path
          domain = domain.replace(/(^\w+:|^)\/\//, "").replace(/\/.*/, "");

          // Check if is a valid domain
          var parseResult = parseDomain(domain);

          if (parseResult.type !== ParseResultType.Listed) {
            toast.error(
              "Invalid domain. Enter only domain/subdomain without protocol or path",
            );
            reject();
            return;
          }

          // Check if subdomain has www
          if (parseResult?.subDomains?.[0] === "www") {
            // Remove www from start of subdomain
            parseResult.hostname = remove_prefix(parseResult.hostname, "www.");
          }

          var newHostname = parseResult?.hostname;

          if (!newHostname) {
            toast.error(
              "Invalid domain. Enter only domain/subdomain without protocol or path",
            );
            reject();
            return;
          }

          newHostname = newHostname?.toLowerCase?.();

          if (!newHostname) {
            toast.error(
              "Invalid domain. Enter only domain/subdomain without protocol or path",
            );
            reject();
            return;
          }

          resolve(newHostname);

          return;
        })
        .catch((e) => {
          toast.error("Loading failed.");
          console.error(e);
          reject();
        });
    });
  };

  const addDomain = async (domain, redirect = false) => {
    return new Promise((resolve, reject) => {
      // Validate if domain is empty
      if (!domain) {
        toast.error("Domain is required");
        reject();
        return;
      }

      // Lazy load parse-domain
      import(
        /* webpackChunkName: "parse-domain" */
        /* webpackMode: "lazy" */
        "parse-domain"
      )
        .then(({ parseDomain, ParseResultType }) => {
          // Remove protocol and path
          domain = domain.replace(/(^\w+:|^)\/\//, "").replace(/\/.*/, "");

          // Check if is a valid domain
          var parseResult = parseDomain(domain);

          if (parseResult.type !== ParseResultType.Listed) {
            toast.error(
              "Invalid domain. Enter only domain/subdomain without protocol or path",
            );
            reject();
            return;
          }

          // Check if subdomain has www
          if (parseResult?.subDomains?.[0] === "www") {
            // Remove www from start of subdomain
            parseResult.hostname = remove_prefix(parseResult.hostname, "www.");
          }

          var newHostname = parseResult?.hostname;

          if (!newHostname) {
            toast.error(
              "Invalid domain. Enter only domain/subdomain without protocol or path",
            );
            reject();
            return;
          }

          newHostname = newHostname?.toLowerCase?.();

          if (!newHostname) {
            toast.error(
              "Invalid domain. Enter only domain/subdomain without protocol or path",
            );
            reject();
            return;
          }

          if (
            userData?.domains?.map((item) => item.domain).includes(newHostname)
          ) {
            toast.error("Domain already exists");
            reject();
            return;
          }

          domainActions("create", {
            domain: newHostname,
          })
            .then((res) => {
              if (res.id) {
                var oldDomains = userData?.domains ?? [];

                setUserData({
                  ...userData,
                  domains: [
                    ...userData?.domains,
                    { id: res.id, domain: newHostname },
                  ],
                });

                setCurrentDomainInternal(newHostname);

                // Save domain to local storage
                localStorage.setItem("currentDomain", newHostname);

                // Set alias
                setAlias(null);

                toast.success("Domain added successfully");
                resolve();

                if (redirect && oldDomains.length === 0) {
                  navigate("/code");
                }
              }
            })
            .catch((e) => {
              toast.error(e.response?.data?.message);
              reject();
            });
        })
        .catch((e) => {
          toast.error("Loading failed.");
          console.error(e);
          reject();
        });
    });
  };

  const updateDomain = async (oldDomain, domain) => {
    return new Promise((resolve, reject) => {
      // Validate if domain is empty
      if (!domain) {
        toast.error("Domain is required");
        reject();
        return;
      }

      if (!oldDomain?.id) {
        toast.error("Current Domain is required");
        reject();
        return;
      }

      oldDomain = {
        ...oldDomain,
      };

      // Lazy load parse-domain
      import(
        /* webpackChunkName: "parse-domain" */
        /* webpackMode: "lazy" */
        "parse-domain"
      )
        .then(({ parseDomain, ParseResultType }) => {
          // Remove protocol and path
          domain = domain.replace(/(^\w+:|^)\/\//, "").replace(/\/.*/, "");

          // Check if is a valid domain
          var parseResult = parseDomain(domain);

          if (parseResult.type !== ParseResultType.Listed) {
            toast.error(
              "Invalid domain. Enter only domain/subdomain without protocol or path",
            );
            reject();
            return;
          }

          // Check if subdomain has www
          if (parseResult?.subDomains?.[0] === "www") {
            // Remove www from start of subdomain
            parseResult.hostname = remove_prefix(parseResult.hostname, "www.");
          }

          var newHostname = parseResult?.hostname;

          if (!newHostname) {
            toast.error(
              "Invalid domain. Enter only domain/subdomain without protocol or path",
            );
            reject();
            return;
          }

          newHostname = newHostname?.toLowerCase?.();

          if (!newHostname) {
            toast.error(
              "Invalid domain. Enter only domain/subdomain without protocol or path",
            );
            reject();
            return;
          }

          if (
            userData?.domains?.map((item) => item.domain).includes(newHostname)
          ) {
            toast.error("Domain already exists");
            reject();
            return;
          }

          domainActions("update", {
            id: oldDomain.id,
            domain: newHostname,
          })
            .then((res) => {
              var newDomains = userData?.domains.map((item) => {
                if (item.id === oldDomain.id) {
                  return { ...item, domain: newHostname, verified: false };
                }
                return item;
              });

              setUserData({
                ...userData,
                domains: newDomains,
              });

              if (oldDomain.domain === currentDomain) {
                setCurrentDomainInternal(newHostname);

                // Save domain to local storage
                localStorage.setItem("currentDomain", newHostname);

                // Set alias
                setAlias(null);
              }

              toast.success("Domain updated successfully");
              resolve();
            })
            .catch((e) => {
              toast.error(e.response?.data?.message);
              reject();
            });
        })
        .catch((e) => {
          toast.error("Loading failed.");
          console.error(e);
          reject();
        });
    });
  };

  function hasPermission(permissionID, domainID) {
    if (domainID) {
      var domainObj = userData?.domains.find((item) => item.id === domainID);
      if (domainObj?.role === "admin") {
        return true;
      }
      if (domainObj?.role === "custom") {
        return domainObj?.permissions?.includes?.(permissionID);
      }
      return true;
    }

    if (alias) {
      var currentDomainObj = userData?.domains?.find(
        (item) => item.domain === currentDomain,
      );

      if (currentDomainObj?.role === "admin") {
        return true;
      }

      if (currentDomainObj?.role === "custom") {
        return currentDomainObj?.permissions?.includes?.(permissionID);
      }

      return false;
    }

    return true;
  }

  const verifyDomainInternal = (domain) => {
    var toastID = toast.loading(<div>Verifying Domain</div>, {
      position: "top-center",
    });

    domainActions("mark-verify", {
      id: domain.id,
    })
      .then((res) => {
        setUserData({
          ...userData,
          domains: userData?.domains.map((item) => {
            if (item.id === domain.id) {
              return { ...item, verified: true };
            }
            return item;
          }),
        });
        setIsVerifyingDomain(false);
        toast.update(toastID, {
          render: "Domain verified successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      })
      .catch((e) => {
        console.error(e);
        setIsVerifyingDomain(false);
        toast.update(toastID, {
          render: (
            <button
              onClick={() => {
                navigate("/code");
              }}
            >
              Domain verification <span className="underline">failed</span>
            </button>
          ),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  };

  const verifyDomain = async (domain) => {
    var childWindow = window.open(
      `http://${
        domain?.domain
      }?timestamp=${new Date().getTime()}&poperVerifyDomain=true`,
      // `http://domain-test.com:5500/index3.html?poperVerifyDomain=true`,
      "poperVerifyDomain",
      "location,toolbar,resizable,scrollbars,status,width=600,height=600",
    );

    // function addVerifyingStateUI(){

    //   console.log("loaded");

    //   // Check if document is loaded

    //   // Add a loading div to the child window
    //   childWindow.document.write(
    //     `<script>
    //     function winLoad(callback) {
    //       console.log("Poper Domain Verification: Document is in state ", document.readyState);
    //       if (document.readyState === "complete") {
    //           callback();
    //       } else {
    //           window.addEventListener("load", callback);
    //       }
    //     }

    //   winLoad(() => {
    //     var div = document.createElement("div");
    //     div.innerHTML = "<div style='position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: center; color: white; font-size: 24px; font-weight: bold;'>Loading...</div>";
    //     document.body.appendChild(div);
    //   });

    //     </script>`,
    //   );
    // }

    // addVerifyingStateUI();

    var hasClosed = false;

    // Auto close window after 60 seconds
    var timer = setTimeout(() => {
      if (!hasClosed) {
        childWindow.close();
        toast.error(
          <button
            onClick={() => {
              navigate("/code");
            }}
          >
            Domain verification <span className="underline">failed</span>
          </button>,
        );
      }
    }, 60000);

    function eventCallback(res) {
      if (
        res.data?.type === "poperVerifyDomain" &&
        res.data?.accountIDs?.includes(
          md5(
            currentDomainObj?.owner_user_email?.toLowerCase?.()
              ? currentDomainObj?.owner_user_email?.toLowerCase?.()
              : userAttributes?.email?.toLowerCase?.() ?? "",
          ),
        )
      ) {
        hasClosed = true;
        childWindow.close();
        verifyDomainInternal(domain);
        clearTimeout(timer);
        window.removeEventListener("message", eventCallback);
      } else {
        hasClosed = true;
        childWindow.close();
        clearTimeout(timer);
        window.removeEventListener("message", eventCallback);

        if (res.data?.type === "poperVerifyDomain") {
          toast.error(
            "Domain verification failed. Embed code is not from your account.",
            {
              autoClose: 10000,
            },
          );
        } else {
          toast.error("Domain verification failed");
        }
      }
    }

    window.addEventListener("message", eventCallback, false);

    var windowClosedTimer = setInterval(checkChild, 500);

    function checkChild() {
      if (childWindow.closed) {
        setTimeout(() => {
          clearInterval(timer);
          clearInterval(windowClosedTimer);
          window.removeEventListener("message", eventCallback);
        }, 1000);
      }
    }

    if (isVerifyingDomain) {
      return;
    }
  };

  const removeDomain = async (domain) => {
    return new Promise((resolve, reject) => {
      if (!domain?.id) {
        toast.error("Domain ID is required");
        reject();
        return;
      }

      // If domain id is not found
      if (!userData?.domains?.map((item) => item.id).includes(domain.id)) {
        toast.error("Domain does not exist");
        reject();
        return;
      }

      domainActions("delete", {
        id: domain.id,
      })
        .then(() => {
          var newDomains = userData?.domains.filter(
            (item) => item.id !== domain.id,
          );

          setUserData({
            ...userData,
            domains: newDomains,
          });

          if (domain.domain === currentDomain) {
            setCurrentDomainInternal(newDomains?.[0]?.domain ?? null);
            localStorage.setItem(
              "currentDomain",
              newDomains?.[0]?.domain ?? null,
            );
          }

          toast.success("Domain removed successfully");
          resolve();
        })
        .catch((e) => {
          reject();
          toast.error(e.response.data.message);
        });
    });
  };

  const addSubDomain = async (subdomain) => {
    return new Promise((resolve, reject) => {
      // Validate if domain is empty
      if (!subdomain?.parent_id) {
        toast.error("Parent domain is required");
        reject();
        return;
      }

      if (!subdomain?.domain) {
        toast.error("Subdomain is required");
        reject();
        return;
      }

      var domain = subdomain?.domain;

      // Lazy load parse-domain
      import(
        /* webpackChunkName: "parse-domain" */
        /* webpackMode: "lazy" */
        "parse-domain"
      )
        .then(({ parseDomain, ParseResultType }) => {
          // Remove protocol and path
          domain = domain?.replace(/(^\w+:|^)\/\//, "").replace(/\/.*/, "");

          // Check if is a valid domain
          var parseResult = parseDomain(domain);

          if (parseResult.type !== ParseResultType.Listed) {
            toast.error(
              "Invalid subdomain. Enter only subdomain without protocol or path",
            );
            reject();
            return;
          }

          // Check if subdomain has www
          if (parseResult?.subDomains?.[0] === "www") {
            // Remove www from start of subdomain
            parseResult.hostname = remove_prefix(parseResult.hostname, "www.");
          }

          const hostName =
            parseResult?.domain + "." + parseResult?.topLevelDomains?.join(".");

          const parentDomain = userData?.domains?.find(
            (x) => x?.id === subdomain?.parent_id,
          )?.domain;

          var parentParseResult = parseDomain(parentDomain);

          const parentHostName =
            parentParseResult?.domain +
            "." +
            parentParseResult?.topLevelDomains?.join(".");

          if (parentHostName !== hostName) {
            toast.error("Please enter subdomain of same root domain.");
            reject();
            return;
          }

          var newHostname = parseResult?.hostname;

          if (!(parseResult?.subDomains?.length > 0)) {
            toast.error(
              "No subdomain found. Enter only subdomain without protocol or path",
            );
            reject();
            return;
          }

          subdomain["domain"] = newHostname;

          if (!newHostname) {
            toast.error(
              "Invalid subdomain. Enter only subdomain without protocol or path",
            );
            reject();
            return;
          }

          newHostname = newHostname?.toLowerCase?.();

          if (!newHostname) {
            toast.error(
              "Invalid subdomain. Enter only subdomain without protocol or path",
            );
            reject();
            return;
          }

          if (
            userData?.domains
              ?.find((x) => x?.id === subdomain?.parent_id)
              ?.subdomains?.find((host) => host?.domain === subdomain?.domain)
          ) {
            toast.error("Subdomain already exists");
            reject();
            return;
          }

          // We also need to check if the subdomain is already added as root domain
          if (userData?.domains?.find((x) => x?.domain === subdomain?.domain)) {
            toast.error("Subdomain already exists");
            reject();
            return;
          }

          domainActions("create-subdomain", {
            subdomain: subdomain,
          })
            .then((res) => {
              if (res?.id) {
                setUserData({
                  ...userData,
                  domains: [
                    ...userData?.domains?.map((tempDomainObj) => {
                      if (subdomain?.parent_id === tempDomainObj?.id) {
                        return {
                          ...(tempDomainObj ?? {}),
                          subdomains: [
                            ...(tempDomainObj?.subdomains ?? []),
                            {
                              id: res?.id,
                              parent_id: subdomain?.parent_id,
                              verified: false,
                              domain: newHostname,
                            },
                          ],
                        };
                      }
                      return tempDomainObj;
                    }),
                  ],
                });

                toast.success("Subdomain added successfully");
                resolve();
              }
            })
            .catch((e) => {
              toast.error(e.response?.data?.message);
              reject();
            });
        })
        .catch((e) => {
          toast.error("Loading failed.");
          console.error(e);
          reject();
        });
    });
  };

  const removeSubDomain = async (subdomain) => {
    return new Promise((resolve, reject) => {
      if (!subdomain?.id) {
        toast.error("Domain ID is required");
        reject();
        return;
      }

      if (
        !userData?.domains
          ?.find((x) => x?.id === subdomain?.parent_id)
          ?.subdomains?.find((host) => host?.id === subdomain?.id)
      ) {
        toast.error("Domain does not exist");
        reject();
        return;
      }

      domainActions("delete-subdomain", {
        parent_id: subdomain?.parent_id,
        id: subdomain?.id,
      })
        .then(() => {
          setUserData({
            ...userData,
            domains: [
              ...userData?.domains?.map((tempDomainObj) => {
                if (subdomain?.parent_id === tempDomainObj?.id) {
                  return {
                    ...(tempDomainObj ?? {}),
                    subdomains: tempDomainObj?.subdomains?.filter(
                      (item) => item?.id !== subdomain?.id,
                    ),
                  };
                }
                return tempDomainObj;
              }),
            ],
          });

          toast.success("Subdomain removed successfully");
          resolve();
        })
        .catch((e) => {
          reject();
          toast.error(e?.response?.data?.message);
        });
    });
  };

  const verifySubDomainInternal = (domain) => {
    var toastID = toast?.loading(<div>Verifying Subdomain</div>, {
      position: "top-center",
    });

    if (
      !userData?.domains
        ?.find((x) => x?.id === domain?.parent_id)
        ?.subdomains?.find((host) => host?.id === domain?.id)
    ) {
      toast.error("Subdomain does not exist");
      reject();
      return;
    }

    domainActions("mark-verify-subdomain", {
      id: domain?.id,
      parent_id: domain?.parent_id,
    })
      .then((res) => {
        setUserData({
          ...userData,
          domains: [
            ...userData?.domains?.map((tempDomainObj) => {
              if (domain?.parent_id === tempDomainObj?.id) {
                return {
                  ...(tempDomainObj ?? {}),
                  subdomains: [
                    ...(tempDomainObj?.subdomains ?? [])?.map((item) => {
                      if (item?.id === domain?.id) {
                        return {
                          ...(item ?? {}),
                          verified: true,
                        };
                      }
                      return item;
                    }),
                  ],
                };
              }
              return tempDomainObj;
            }),
          ],
        });

        setIsVerifyingDomain(false);
        toast?.update(toastID, {
          render: "Subdomain verified successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      })
      .catch((e) => {
        console.error(e);
        setIsVerifyingDomain(false);
        toast?.update(toastID, {
          render: (
            <button
              onClick={() => {
                navigate("/code");
              }}
            >
              Domain verification <span className="underline">failed</span>
            </button>
          ),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  };

  const verifySubDomain = async (domain) => {
    var childWindow = window.open(
      `http://${
        domain?.domain
      }?timestamp=${new Date()?.getTime()}&poperVerifyDomain=true`,
      "poperVerifyDomain",
      "location,toolbar,resizable,scrollbars,status,width=600,height=600",
    );

    var currentDomainObj = userData?.domains?.find(
      (item) => item?.id === domain?.parent_id,
    );

    var hasClosed = false;

    // Auto close window after 60 seconds
    var timer = setTimeout(() => {
      if (!hasClosed) {
        childWindow.close();
        toast.error(
          <button
            onClick={() => {
              navigate("/code");
            }}
          >
            Subdomain verification <span className="underline">failed</span>
          </button>,
        );
      }
    }, 60000);

    function eventCallback(res) {
      console.log(domain);
      if (
        res?.data?.type === "poperVerifyDomain" &&
        res.data?.accountIDs?.includes(
          md5(
            currentDomainObj?.owner_user_email?.toLowerCase?.()
              ? currentDomainObj?.owner_user_email?.toLowerCase?.()
              : userAttributes?.email?.toLowerCase?.() ?? "",
          ),
        )
      ) {
        hasClosed = true;
        childWindow.close();
        verifySubDomainInternal(domain);
        clearTimeout(timer);
        window.removeEventListener("message", eventCallback);
      } else {
        hasClosed = true;
        childWindow.close();
        clearTimeout(timer);
        window.removeEventListener("message", eventCallback);

        if (res.data?.type === "poperVerifyDomain") {
          toast.error(
            "Domain verification failed. Embed code is not from your account.",
            {
              autoClose: 10000,
            },
          );
        } else {
          toast.error("Domain verification failed");
        }
      }
    }

    window.addEventListener("message", eventCallback, false);

    var windowClosedTimer = setInterval(checkChild, 500);

    function checkChild() {
      if (childWindow.closed) {
        setTimeout(() => {
          clearInterval(timer);
          clearInterval(windowClosedTimer);
          window.removeEventListener("message", eventCallback);
        }, 1000);
      }
    }

    if (isVerifyingDomain) {
      return;
    }
  };

  useEffect(() => {
    // Send heartbeat to server
    sendHeartbeat();
    var interval = setInterval(() => {
      try {
        sendHeartbeat();
      } catch (e) {
        console.error(e);
      }
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const isSubscribed = userData?.subscription?.status === "active";

  const subscriptionPlan = (userData?.subscription?.plan ?? "free")
    ?.toLowerCase()
    ?.replace("-yearly", "");

  var currentSubscriptionPlanDetails = isSubscribed
    ? PLANS.find((item) => item.id === subscriptionPlan)
    : PLANS[0];

  var currentDomainObj = userData?.domains?.find(
    (item) => item.domain === currentDomain,
  );

  if (isLoading) {
    return (
      <>
        <MainSkeleton />
      </>
    );
  }

  return (
    <PoperAccount.Provider
      value={{
        userData,
        setUserData,
        currentDomain,
        currentDomainObj,
        setCurrentDomainInternal,
        setAlias,
        setCurrentDomain: (domain) => {
          setCurrentDomainInternal(domain);
          localStorage.setItem("currentDomain", domain);

          // Set alias
          var domainObj = userData?.domains.find(
            (item) => item.domain === domain,
          );
          if (domainObj?.external) {
            setAlias({
              owner: domainObj?.owner_user_email,
            });
          } else {
            setAlias(null);
          }
        },
        hasPermission,
        addDomain,
        addSubDomain,
        removeSubDomain,
        verifySubDomainInternal,
        verifySubDomain,
        verifyDomain,
        removeDomain,
        updateDomain,
        isSubscribed,
        subscriptionPlan,
        currentSubscriptionPlanDetails,
        getInitialAccountReactState,
        setInitialAccountReactState,
        removeInitialAccountReactStates,
        validateNewDomain,
        isAddingNewPopupModalVisible,
        setIsAddingNewPopupModalVisible,
        newPopupConfig,
        setNewPopupConfig,
        consumptionPercentage,
      }}
    >
      <ProductLiftScript />
      <Suspense fallback={<></>}>
        <ForceUpdateNames
          open={forceUpdateNames}
          setOpen={setForceUpdateNames}
        />
      </Suspense>
      {children}
    </PoperAccount.Provider>
  );
};
